@import "./Navbar.css";
@import "./Home.css";
@import "./About.scss";
@import "./Portfolio.css";
@import "./Contact.css";
@import "./Resume.css";
@import "./NoMatch.css";

@font-face {
  font-family: NameFont;
  src: url(../fonts/Vindica.otf);
}

section {
  position: relative;
  display: flex;
  height: 100vh;
  width: calc(max(10px, 100%));
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  // background-color: #f4f4f4;
  // background-image: url(../images/1.jpg);
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
}

section:nth-child(2) {
  // background-image: url(../images/2.jpg);
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
  height: 100vh;
}

section:nth-child(3) {
  // background-image: url(../images/3.jpg);
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
  box-shadow: inset 0px -35px 40px 2px #000000;
}

.scrolls {
  font-family: NameFont;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: calc(min(1.25em, 2vw));
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: calc(min(2rem, 8vw));
  color: #ffffff;
}

.scrolls .center {
  font-size: calc(min(1.25rem, 3vw));
}