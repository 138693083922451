#Contact {
  position: relative;
  display: flex;
  height: 100vh;
  width: calc(max(10px, 100%));
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
}

.Contact {
  padding-top: 4vh;
}

.Contact ul {
  display: flex;
  justify-content: center;
  position: relative;
}

.Contact ul li {
  list-style: none;
}

.Contact ul li a {
  width: 60px;
  height: 60px;
  background-color: #fff;
  text-align: center;
  line-height: 60px;
  font-size: 35px;
  margin: 0 10px;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 3px solid #fff;
  z-index: 1;
}

.Contact ul li a .icon {
  position: relative;
  color: #262626;
  transition: 0.5s;
  z-index: 3;
}

.Contact ul li a:hover .icon {
  color: #fff;
  transform: rotateY(360deg);
}

.Contact ul li a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f00;
  transition: 0.5s;
  z-index: 2;
}

.Contact ul li a:hover:before {
  top: 0;
}

.Contact ul li:nth-child(1) a:before {
  background: #a72ab3;
}

.Contact ul li:nth-child(2) a:before {
  background: #55acee;
}

.Contact ul li:nth-child(3) a:before {
  background: #dd4b39;
}

.copyright p {
  font-size: 14px;
  color: white;
  text-align: center;
  padding-top: 3vh;
}

.year2020 p {
  font-size: 14px;
  color: white;
  text-align: center;
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
}

.contact-form {
  display: grid;
  justify-content: center;
  padding-top: 25vh;
  padding-bottom: 1vh;
}

.contact-form .mb-3 {
  padding-top: 10px;
}

.form-group {
  margin-bottom: 1rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
  width: 30rem;
}

.form-control {
  display: block;
  width: -webkit-fill-available;
  height: 1.5rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  border: 3px solid #858585;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.contact-form h1 {
  position: relative;
  font-family: MainFont;
  letter-spacing: 2px;
  font-size: 40px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  color: rgb(255, 255, 255);
}

.text-muted {
  color: white;
  margin-bottom: 0.5rem;
  font-family: NameFont;
  letter-spacing: 0.1em;
  padding-top: 5px;
  font-size: 20px;
}

#exampleText {
  padding-bottom: 100px;
  text-justify: distribute;
}

.submit-button {
  --c: rgb(14, 110, 10);
  color: var(--c);
  font-size: 16px;
  border: 0.3em solid var(--c);
  border-radius: 0.5em;
  width: 50%;
  height: 2em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: sans-serif;
  letter-spacing: 0.1em;
  text-align: center;
  line-height: 1.5em;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.5s;
  margin-left: 25%;
  font-family: NameFont;
  margin-top: 30px;
}

.submit-button span {
  position: absolute;
  width: 25%;
  height: 100%;
  background-color: var(--c);
  transform: translateY(150%);
  border-radius: 50%;
  left: calc((var(--n) - 1) * 25%);
  transition: 0.5s;
  transition-delay: calc((var(--n) - 1) * 0.1s);
  z-index: -1;
}

.submit-button:hover {
  color: rgb(255, 255, 255);
}

.submit-button:hover span {
  transform: translateY(0) scale(2);
}

.submit-button span:nth-child(1) {
  --n: 1;
}

.submit-button span:nth-child(2) {
  --n: 2;
}

.submit-button span:nth-child(3) {
  --n: 3;
}

.submit-button span:nth-child(4) {
  --n: 4;
}
