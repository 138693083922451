@font-face {
  font-family: NavbarFont;
  src: url(../fonts/Vindica.otf);
}

.navbar-link {
  cursor: pointer;
}

.header {
  top: 0;
  z-index: 2000;
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 60px;
  background-image: linear-gradient(180deg, #1d1e22da, rgba(29, 30, 34, 0.411), transparent);
}

.header ul {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: calc(min(1em, 3vw));
  letter-spacing: 4px;
  line-height: 1.5;
}

.header ul li {
  margin: 0 1em;
  padding: calc(min(1em, 1.5vw));
  padding-top: calc(min(1em, 4vw));
}

.header ul li a {
  font-family: NavbarFont;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 2px 2px 2px #ffffff9a;
}

.header ul li a::after {
  position: absolute;
  bottom: -3px;
  left: 0;
  content: "";
  width: 0;
  height: 2px;
  transition: all 0.3s linear;
}

.header ul li a:hover::after {
  width: 100%;
  background-color: #ffffff;
}
