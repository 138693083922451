@font-face {
    font-family: NotFoundFont;
    src: url(../fonts/Delogy.ttf);
    src: url(../fonts/ForestSmooth.ttf);
    src: url(../fonts/Vindica.otf);
}

#notfound {
    position: relative;
    height: 100vh;
}
  
#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
  
.notfound {
    max-width: 410px;
    width: 100%;
    text-align: center;
}
  
.notfound .notfound-404 {
    height: 280px;
    position: relative;
    z-index: -1;
}
  
.notfound .notfound-404 h1 {
    font-family: NotFoundFont;
    letter-spacing: 20px;
    font-size: 300px;
    margin: 0px;
    font-weight: 900;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    background: url("../images/1.jpg") no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: cover;
    background-position: center;
}
  
.notfound h2 {
    font-family: 'Montserrat', sans-serif;
    color: #000;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
}
  
.notfound p {
    font-family: 'Montserrat', sans-serif;

    color: #000;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 15px;
}
  
.notfound a {
    font-family: NotFoundFont;
    letter-spacing: 2px;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    background: #256d68;
    display: inline-block;
    padding: 15px 30px;
    border-radius: 40px;
    color: #fff;
    font-weight: 700;
    -webkit-box-shadow: 0px 4px 15px -5px #256d68;
            box-shadow: 0px 4px 15px -5px #256d68;
}
  
  
@media only screen and (max-width: 767px) {
    .notfound .notfound-404 {
        height: 142px;
    }
    .notfound .notfound-404 h1 {
        font-size: 112px;
    }
}
  