@font-face {
  font-family: MainFont;
  src: url(../fonts/Vindica.otf);
}

.about {
  background-color: #ffffff;
  border-radius: 20px 20px 20px 20px;
  width: 75vw;
  padding: 20px;

  &-header {
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 155px;
  }

  &-bottom {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &-img {
    width: 150px;
    border-radius: 20px;
  }

  &-photo {
    float: left;
    height: fit-content;
    padding-top: 5px;
    padding-right: 10px;
  }

  &-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    &-header,
    &-bottom {
      font-family: MainFont;
      letter-spacing: 2px;
      font-size: clamp(1.7rem, 2.2vw, 4rem);
      text-align: center;
      text-decoration: underline;
      color: rgb(0, 34, 10);
    }

    &-content {
      font-family: "PT Sans";
      text-align: justify;
      text-align-last: auto;
      font-size: clamp(0.9rem, 2vw, 1.2rem);
      line-height: 1.4;
      color: rgb(0, 34, 10);
    }
  }

  &-skills {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    & .icon {
      filter: grayscale(60%);
      opacity: 0.6;
    }

    & .icon:hover {
      filter: grayscale(0%);
      filter: brightness(121%);
      transition: all 0.1s ease;
      opacity: 1;
    }

    & a {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      align-items: center;
      color: black;
      gap: 10px
    }

    & img {
      display: flex;
      justify-content: center;

      @media (max-width: 480px) {
        width: 45px;
        height: 45px;
      }
    }

    & span {
      text-align: center;
      font-family: "PT Sans";
      color: rgb(0, 34, 10);
      font-size: clamp(0.8rem, 1.5vw, 1rem);
    }
  }
}