@font-face {
    font-family: MainFont;
    src: url(../fonts/Vindica.otf);
}

.pdf-content {
    width: 100%;
    background-image: url(../images/img11.jpg);
    background-size: cover;
    background-repeat: repeat;
    height: 100vh;
}

.pdf-page {
    padding-top: 1em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-size: 19px;
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
    z-index: 1;
}

.page-controls {
    position: relative;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 4px;
}

.pager {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5em;
    font-family: MainFont!important;
}

.pager li {
    padding: 1em;
    width: 6em;
}

.pager li a{
    text-decoration: none;
    background-color: rgb(58, 58, 58);
    padding: 10px;
    border-radius: 10px;
    color: white;
}

.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
}

.download-buttons {
    display: flex;
    justify-content: center;
    width: 45%;
    margin: 0 auto 0 auto;
    padding-top: 1em;
    padding-bottom: 1.95em;
}

.download-doc {
    width: 200px;
    float: right;
    margin-right: 35px;
    --c: rgb(58, 58, 58);
}

.download-pdf {
    width: 200px;
    float: right;
    --c: rgb(58, 58, 58);
}

.buttons-border {
    /* border: 3px solid #ffffff; */
    margin: 0 auto 0 auto;
    width: 589px;
    padding: 10px;
}

/* @media screen and (max-width: 1366px) {
    .pdf-content {
        width: 100%;
        background-image: url(../images/img11.jpg);
        background-size: cover;
        background-repeat: repeat;
        height: auto;
    }
} */