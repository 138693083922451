.Portfolio {
  width: 80vw;
  display: flex;
  justify-content: center;
}

.grid {
  position: relative;
  clear: both;
  padding: 1em 0 4em;
  /* max-width: 630px; */
  list-style: none;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.grid figure {
  position: relative;
  float: left;
  overflow: hidden;
  margin: 10px 1%;
  min-width: 300px;
  max-width: 300px;
  height: auto;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.4;
}

.grid figure figcaption {
  padding-top: 1em;
  padding-left: 0em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure h2 {
  font-weight: 300;
  text-align: center;
}

.grid figure h3 {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.5s;
  transition: opacity 0.35s, transform 0.55s;
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
  font-size: 16px;
  font-weight: 300;
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  text-transform: none;
  text-align-last: center;
  text-align: center;
  font-style: italic;
}

figure.effect-julia:hover h3 {
  -webkit-transform: translate3d(360px, 0, 0);
  transform: translate3d(360px, 0, 0);
}

.grid figure h2 span {
  font-weight: 800;
}

.grid figure h2,
.grid figure p {
  margin: 0;
}

.grid figure p {
  letter-spacing: 1px;
  font-size: 68.5%;
}

figure.effect-julia {
  background: #2f3238;
  border: 1px solid white;
}

figure.effect-julia img {
  height: 200px;
  -webkit-transition: opacity 1s, -webkit-transform 1s;
  transition: opacity 1s, transform 1s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure.effect-julia figcaption {
  text-align: left;
}

figure.effect-julia h2 {
  position: relative;
  padding: 0.5em 0;
  padding-top: 2px;
}

figure.effect-julia p {
  display: inline-block;
  margin: 0 0 0.5em;
  padding: 0.2em 1em;
  background: rgba(255, 255, 255, 0.815);
  color: #2f3238;
  text-transform: none;
  font-weight: 500;
  font-size: 75%;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.5s;
  transition: opacity 0.35s, transform 0.55s;
  -webkit-transform: translate3d(-360px, 0, 0);
  transform: translate3d(-360px, 0, 0);
  text-align: center;
  width: 270px;
}

figure.effect-julia p:first-child {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

figure.effect-julia p:nth-of-type(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

figure.effect-julia p:nth-of-type(3) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

figure.effect-julia:hover p:first-child {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

figure.effect-julia:hover p:nth-of-type(2) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

figure.effect-julia:hover p:nth-of-type(3) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

figure.effect-julia:hover img {
  opacity: 0.2;
  -webkit-transform: scale3d(1.1, 1.1, 1);
  transform: scale3d(1.1, 1.1, 1);
}

figure.effect-julia:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

#outside-links i {
  font-size: 25px;
  padding: 2px;
  margin-left: 3px;
  margin-right: 3px;
  color: black;
}

#outside-links i:hover {
  color: green;
}

#outside-links .fa-github:hover {
  color: purple;
}

@media screen and (min-width: 1006px) and (max-width: 1204px) {
  .grid {
    padding: 0;
  }

  .grid figure {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 10px 1%;
    min-width: 250px;
    max-width: 250px;
    height: auto;
    text-align: center;
    cursor: pointer;
  }

  .grid figure figcaption {
    padding-top: 0.3em;
  }

  figure.effect-julia h2 {
    position: relative;
    padding: 0.5em 0;
    padding-top: 2px;
  }

  figure.effect-julia:hover h3 {
    -webkit-transform: translate3d(250px, 0, 0);
    transform: translate3d(250px, 0, 0);
  }

  figure.effect-julia p {
    display: inline-block;
    margin: 0 0 0.5em;
    padding: 0.2em 1em;
    background: rgba(255, 255, 255, 0.815);
    color: #2f3238;
    text-transform: none;
    font-weight: 500;
    font-size: 60%;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.5s;
    transition: opacity 0.35s, transform 0.55s;
    -webkit-transform: translate3d(-260px, 0, 0);
    transform: translate3d(-260px, 0, 0);
    text-align: center;
    width: 230px;
  }

  figure.effect-julia img {
    height: 170px;
  }
}

@media screen and (max-width: 1006px) {
  .grid {
    padding: 0;
  }

  .grid figure {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 10px 1%;
    min-width: 200px;
    max-width: 200px;
    height: auto;
    text-align: center;
    cursor: pointer;
  }

  .grid figure figcaption {
    padding-top: 0.3em;
  }

  figure.effect-julia h2 {
    position: relative;
    padding: 0.2em 0;
    padding-top: 2px;
  }

  figure.effect-julia:hover h3 {
    -webkit-transform: translate3d(250px, 0, 0);
    transform: translate3d(250px, 0, 0);
  }

  figure.effect-julia p {
    display: inline-block;
    margin: 0 0 0.5em;
    padding: 0.2em 1em;
    background: rgba(255, 255, 255, 0.815);
    color: #2f3238;
    text-transform: none;
    font-weight: 500;
    font-size: 50%;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.5s;
    transition: opacity 0.35s, transform 0.55s;
    -webkit-transform: translate3d(-260px, 0, 0);
    transform: translate3d(-260px, 0, 0);
    text-align: center;
    width: 180px;
  }

  figure.effect-julia img {
    height: 140px;
  }

  .grid figure h2 {
    font-weight: 300;
    text-align: center;
    font-size: 15px;
  }

  .grid figure h3 {
    -webkit-transition: opacity 0.35s, -webkit-transform 0.5s;
    transition: opacity 0.35s, transform 0.55s;
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
    font-size: 14px;
    font-weight: 300;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    text-transform: none;
    text-align-last: center;
    text-align: center;
    font-style: italic;
  }
}

@media screen and (max-width: 806px) {
  .grid {
    padding: 0;
  }

  .grid figure {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 10px 1%;
    min-width: 30%;
    max-width: 40%;
    height: auto;
    text-align: center;
    cursor: pointer;
  }

  .grid figure figcaption {
    padding-top: 0.3em;
  }

  figure.effect-julia h2 {
    position: relative;
    padding: 0.2em 5px;
    padding-top: 5px;
  }

  figure.effect-julia:hover h3 {
    -webkit-transform: translate3d(250px, 0, 0);
    transform: translate3d(250px, 0, 0);
  }

  figure.effect-julia p {
    display: inline-block;
    margin: 0 0 0.5em;
    padding: 0.2em 1em;
    background: rgba(255, 255, 255, 0.815);
    color: #2f3238;
    text-transform: none;
    font-weight: 500;
    font-size: 50%;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.5s;
    transition: opacity 0.35s, transform 0.55s;
    -webkit-transform: translate3d(-260px, 0, 0);
    transform: translate3d(-260px, 0, 0);
    text-align: center;
    width: 25%;
  }

  figure.effect-julia img {
    height: 80px;
  }

  .grid figure h2 {
    font-weight: 300;
    text-align: center;
    font-size: 15px;
  }

  .grid figure h3 {
    display: none;
  }

  figure.effect-julia:hover p:first-child {
    display: none;
  }

  figure.effect-julia:hover p:nth-of-type(2) {
    display: none;
  }

  figure.effect-julia:hover p:nth-of-type(3) {
    display: none;
  }

  figure.effect-julia:hover p {
    display: none;
  }

  figure.effect-julia p:first-child {
    display: none;
  }

  figure.effect-julia p:nth-of-type(2) {
    display: none;
  }

  figure.effect-julia p:nth-of-type(3) {
    display: none;
  }
}
