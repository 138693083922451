.Home {
  margin-bottom: 2rem;
}

.welcome-home1 {
  font-family: NameFont;
  font-size: calc(max(40px, 8vw));
  letter-spacing: 2px;
  color: white;
  text-transform: uppercase;
  animation: glow 3s ease-in-out infinite alternate;
}

.welcome-home2 {
  font-family: NameFont;
  font-size: calc(max(40px, 8vw));
  letter-spacing: 2px;
  color: white;
  text-transform: uppercase;
  animation: glow 3s ease-in-out infinite alternate;
  margin-left: 7vw;
  margin-bottom: 1vw;
}

@keyframes glow {
  from {
    text-shadow: 0 0 20px #1c4048;
  }
  to {
    text-shadow: 0 0 30px #ffffff8a, 0 0 10px #ffffff8a;
  }
}

.Home-links {
  display: flex;
  justify-content: center;
}

.Home-links ul li {
  width: calc(min(40px, 7vw));
  height: calc(min(40px, 7vw));
  line-height: calc(min(40px, 7vw));
  margin: 0 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  border: calc(min(4px, 1vw)) solid #d8e2dc;
  float: left;
  transition: all 0.5s ease;
}

.Home-links ul li .fab {
  color: #d8e2dc;
  transition: all 0.5s ease;
  font-size: calc(min(25px, 5vw));
  margin-top: calc(min(7px, 1vw));
}

.Home-links ul li:hover.linkedin {
  border: 4px solid #55acee;
  box-shadow: 0 0 15px #55acee;
  transition: all 0.5s ease;
}

.Home-links ul li:hover .fa-linkedin {
  color: #55acee;
  text-shadow: 0 0 15px #55acee;
  transition: all 0.5s ease;
}

.Home-links ul li:hover.github {
  border: 4px solid #a72ab3;
  box-shadow: 0 0 15px #a72ab3;
  transition: all 0.5s ease;
}

.Home-links ul li:hover .fa-github {
  color: #a72ab3;
  text-shadow: 0 0 15px #a72ab3;
  transition: all 0.5s ease;
}

.Home-links ul li:hover.google {
  border: 4px solid #dd4b39;
  box-shadow: 0 0 15px #dd4b39;
  transition: all 0.5s ease;
}

.Home-links ul li:hover .fa-file {
  color: #dd4b39;
  text-shadow: 0 0 15px #dd4b39;
  transition: all 0.5s ease;
}

.tooltip {
  padding: 6px;
  border-radius: 10px;
}
